<template>
    <div class="area_page">
        <!-- 操作栏 -->
        <div class="operation">
            <el-button type="primary" style="margin-left: 15px" @click="handleDownload($route.query.id, 4, { fileType: 'excel' })">导出为excel</el-button>
        </div>
        <!-- 表格 -->
        <div class="table_box">
            <el-table :data="tableDataPage" stripe border style="width: 100%" :header-cell-style="{ background: '#f5f5f5', color: '#80899b' }">
                <el-table-column label="品牌" prop="brandName" min-width="100" />
                <el-table-column v-for="item in titleList" :key="item" :prop="item" :label="item" align="center">
                    <template #header>
                        <div>
                            <p>{{ item?.split("+")[0] }}</p>
                            <p>{{ item?.split("+")[1] }}</p>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page_box">
                <el-pagination layout="prev, pager, next, total" background :total="total" :page-size="pageSize" :current-page="pageNum" @current-change="currentChange"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script setup>
import useTable from "./useTable";
import useResultStore from "@/store/egpm-brand/useResultStore";
import useDownload from "../useDownload";

const resultStore = useResultStore();
// 注册主组件搜索事件
resultStore.setSearchEvent(() => {
    search();
});

// 表格
const { pageNum, pageSize, total, titleList, tableDataPage, search, currentChange } = useTable({ resultStore });

// 创建下载任务
const { handleDownload } = useDownload({ resultStore });
</script>

<style lang="scss" scoped>
.area_page {
    .operation {
        display: flex;
        justify-content: flex-end;
    }
    .table_box {
        margin-top: 15px;
        .page_box {
            padding-top: 30px;
            padding-bottom: 15px;
            display: flex;
            justify-content: flex-end;
        }
        .default_img {
            width: 150px;
            height: 70px;
            background-color: #f2f2f2;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
                width: 3em;
                height: 3em;
                color: #999;
            }
        }
    }
}
</style>
